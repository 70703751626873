import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Vimeo } from "@swizec/gatsby-theme-course-platform";
export const title = "Module 3";
export const description = "";
export const image = "/chapter_headers/serverless-elements.png";
export const _frontmatter = {};
const layoutProps = {
  title,
  description,
  image,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "module-3-building-your-app"
    }}>{`Module 3: Building your app`}</h1>
    <Vimeo id="392226129" mdxType="Vimeo" />
    <p>{`In `}<a parentName="p" {...{
        "href": "/module-3"
      }}>{`Module 3`}</a>{` you start creating an app based on the framework you set up in `}<a parentName="p" {...{
        "href": "/module-2"
      }}>{`Module 2`}</a>{`. Much of the focus goes towards CRUD - create, read, update, delete - operations based on GraphQL and DynamoDB working together.`}</p>
    <p>{`You can find code for this module `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/markdownlandingpage.com"
      }}>{`on GitHub`}</a>{`. Commits follow the flow of these videos.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      